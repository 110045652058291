import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { animationInFastTime, animationInTimings, animationOutFastTime, animationOutTimings } from '../constants/animations';

export const fadeStaggerFastAnimation = trigger('fadeStaggerFastAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        stagger(animationInFastTime, [animate(animationInTimings, style({ opacity: 'revert-layer', transform: 'scale(1)' }))]),
      ],
      { optional: true },
    ),
    query(':leave', [stagger(animationOutFastTime, [animate(animationOutTimings, style({ opacity: 0, transform: 'scale(0.95)' }))])], {
      optional: true,
    }),
  ]),
]);
