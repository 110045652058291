import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { animationOutTime, animationOutTimings } from '../constants/animations';

export const fadeStaggerOutAnimation = trigger('fadeStaggerOutAnimation', [
  transition('* => *', [
    query(':leave', [stagger(animationOutTime, [animate(animationOutTimings, style({ opacity: 0, transform: 'scale(0.95)' }))])], {
      optional: true,
    }),
  ]),
]);
